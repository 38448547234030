import { useRouter } from "next/router";
import { cn } from "utils/cn";
import Link from "next/link";
import { getPathNameFromListingPath } from "helpers/listings";
import type { HomeTab } from "types/model/home-tab";
import type { Field } from "types/model/field";
import type { FieldOption } from "types/model/field-option";
import type { Venue } from "types/model/venue";

interface HomeTabsProps {
  tabs: HomeTab<Field, FieldOption, Venue>[];
}

const HomeTabs = ({ tabs }: HomeTabsProps) => {
  const router = useRouter();

  const getIsCurrentTab = (
    tab: HomeTab<Field, FieldOption, Venue>,
    asPath: string
  ) => {
    return asPath.startsWith(`/listing/${tab.path}`);
  };

  const getIsAllSelected = (asPath: string) => {
    return asPath.startsWith("/?") || asPath === "/";
  };

  return (
    <div className="mb-6">
      <nav
        className="relative z-0 flex divide-x divide-gray-200 overflow-x-auto rounded-lg shadow"
        aria-label="Tabs"
      >
        <Link
          href={{
            pathname: "/",
            query: router.query
          }}
        >
          <a
            className={cn(
              getIsAllSelected(router.asPath)
                ? "text-indigo-600"
                : "text-gray-500 hover:text-gray-700",
              "rounded-l-lg",
              "group relative flex-1 whitespace-nowrap bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
            aria-current={getIsAllSelected(router.asPath) ? "page" : undefined}
          >
            <span>All</span>
            <span
              aria-hidden="true"
              className={cn(
                getIsAllSelected(router.asPath)
                  ? "bg-indigo-500"
                  : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </a>
        </Link>
        {tabs
          .filter(tab => tab.enabled)
          .map((tab, tabIdx) => (
            <Link
              key={tab._id}
              href={{
                pathname: getPathNameFromListingPath(tab.path),
                query: router.query
              }}
            >
              <a
                className={cn(
                  getIsCurrentTab(tab, router.asPath)
                    ? "text-indigo-600"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === tabs.filter(tab => tab.enabled).length - 1
                    ? "rounded-r-lg"
                    : "",
                  "group relative flex-1 whitespace-nowrap bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={
                  getIsCurrentTab(tab, router.asPath) ? "page" : undefined
                }
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={cn(
                    getIsCurrentTab(tab, router.asPath)
                      ? "bg-indigo-500"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            </Link>
          ))}
      </nav>
    </div>
  );
};

export default HomeTabs;
