import Loader from "components/Loader";
import HomeActivitiesListing from "components/site/HomeActivitiesListing";
import HomeActivitySessionsListing from "components/site/HomeActivitySessionsListing";
import HomeFilters from "components/site/HomeFilters";
import HomeTabs from "components/site/HomeTabs";
import Layout from "components/site/Layout";
import { getListingPathFromAsPath } from "helpers/listings";
import useClient from "hooks/useClient";
import useHomeTabs from "hooks/useHomeTabs";
import {
  BooleanParam,
  ObjectParam,
  useQueryParam,
  withDefault
} from "next-query-params";
import { useRouter } from "next/router";
import React from "react";
import { ListingType } from "types/model/client";
import { cn } from "utils/cn";

interface HomeProps {
  isIframe: boolean;
}

const Home = (props: HomeProps): React.ReactElement => {
  const router = useRouter();
  const [filters, setFilters] = useQueryParam(
    "filters",
    withDefault(ObjectParam, {})
  );
  const homeTabsQueryInfo = useHomeTabs();

  const isIframe = props.isIframe || router.asPath.includes("/iframe");
  const listingPath = getListingPathFromAsPath(router.asPath);
  const clientQueryInfo = useClient();

  const useTransparentBg = useQueryParam(
    "useTransparentBg",
    withDefault(BooleanParam, false)
  );

  const shouldUseGreyBg = !useTransparentBg[0];

  return (
    <Layout
      preventAccessIfClientDisabled={true}
      shouldUseWhiteBg={!shouldUseGreyBg}
      isIframe={isIframe}
    >
      <div
        className={cn(
          "flex flex-row-reverse flex-wrap",
          !isIframe && "md:flex-nowrap md:items-start"
        )}
      >
        <div
          className={cn(
            "mb-6 w-full",
            !isIframe &&
              "md:z-5 md:sticky md:top-110 md:order-1 md:mb-0 md:mr-6 md:w-3/12 md:rounded-lg md:bg-white",
            !shouldUseGreyBg && !isIframe && "md:border md:border-gray-200",
            shouldUseGreyBg && !isIframe && "md:shadow"
          )}
        >
          <HomeFilters
            filters={filters}
            listingPath={listingPath}
            isIframe={isIframe}
            setFilters={setFilters}
          />
        </div>
        <div className={cn("w-full", !isIframe && "md:w-9/12")}>
          {!isIframe &&
            homeTabsQueryInfo.data?.filter(tab => tab.enabled).length > 0 && (
              <HomeTabs tabs={homeTabsQueryInfo.data} />
            )}
          {!clientQueryInfo.data && <Loader />}
          {clientQueryInfo.data?.homePageDisplay?.listingType ===
            ListingType.Activities && (
            <HomeActivitiesListing
              shouldUseGreyBg={shouldUseGreyBg}
              filters={filters}
              isIframe={isIframe}
              listingPath={listingPath}
              client={clientQueryInfo.data}
            />
          )}
          {clientQueryInfo.data?.homePageDisplay?.listingType ===
            ListingType.Sessions && (
            <HomeActivitySessionsListing
              shouldUseGreyBg={shouldUseGreyBg}
              filters={filters}
              isIframe={isIframe}
              listingPath={listingPath}
              client={clientQueryInfo.data}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export async function getServerSideProps(context) {
  return {
    props: {
      isIframe: context.req.url.includes("/iframe")
    }
  };
}

export default Home;
