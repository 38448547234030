import { PlusIcon } from "@heroicons/react/24/solid";
import { cn } from "utils/cn";
import useCurrentUser from "hooks/useCurrentUser";
import Link from "next/link";
import EmptySearch from "public/images/empty-search.svg";

interface ActivitiesListingNoResultsProps {
  hasFiltersApplied: boolean;
  shouldUseGreyBg: boolean;
}

const ActivitiesListingNoResults = ({
  hasFiltersApplied,
  shouldUseGreyBg
}: ActivitiesListingNoResultsProps) => {
  const currentUserQueryInfo = useCurrentUser();
  return (
    <div
      className={cn(
        "rounded-lg bg-white px-8 pb-8 pt-12",
        shouldUseGreyBg && "shadow",
        !shouldUseGreyBg && "border-gray-20 border"
      )}
      data-cy="activities-listing-no-results"
    >
      <div className="mb-10 flex justify-center">
        <EmptySearch />
      </div>
      <div className="mb-4 flex justify-center text-center">
        <p className="text-gray-900">
          {hasFiltersApplied
            ? "Ooops! No Results."
            : "Looks like no activities have been created for booking at the moment."}
        </p>
      </div>
      {currentUserQueryInfo.data?.accessLevel >= 10 && (
        <div className="mt-6 text-center">
          <Link href="/admin/activities/create">
            <a className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Create activity
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ActivitiesListingNoResults;
